import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/s_provision-documentation_master/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`ES System Release Notes`}</h1>
    <p>{`Here you will find information on ES system releases and updates in Production Provision Portal.`}</p>
    <p><strong parentName="p">{`Please read the following before proceeding.`}</strong></p>
    <h2>{`Production Deployments`}</h2>
    <p>{`Production Deployments are those done in/to a Customers network via `}<a parentName="p" {...{
        "href": "https://provision-portal.bddevops.com/"
      }}>{`Provision Portal`}</a>{`. These deployments are “locked down” to specific users only and can only be executed with the approval from the R&D System Release process and DevOps. Software must be released and promoted to the production instance of Artifactory and Customers must be informed of any actions taken on their systems or networks.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      